module.exports = {
    siteMetadata: {
        title: 'Witczax',
        metaTitle: 'Witczax - Challenges of Engineering Management',
        author: 'Krzysztof Witczak',
        description: 'A personal blog of Krzysztof Witczak, a technical leader, programmer and engineering manager in software development.',
        siteUrl: 'https://witczax.com/',
        social: {
            twitter: 'K_Witczak_',
        },
    },
    plugins: [
        {
            resolve: `gatsby-plugin-google-fonts`,
            options: {
                    fonts: [
                    `poppins\:400,700,900`,
                    'open sans\:400,700,900',
                    ],
                }
        },
        {
            resolve: 'gatsby-source-filesystem',
            options: {
                path: `${__dirname}/content/blog`,
                name: 'blog',
            },
        },
        {
            resolve: 'gatsby-source-filesystem',
            options: {
                path: `${__dirname}/content/assets`,
                name: 'assets',
            },
        },
        {
            resolve: 'gatsby-transformer-remark',
            options: {
                plugins: [
                    {
                        resolve: 'gatsby-remark-images',
                        options: {
                            maxWidth: 720,
                            loading: 'eager',
                            disableBgImage: true
                        },
                    },
                    {
                        resolve: "gatsby-remark-embed-youtube",
                        options: {
                          width: 720,
                          height: 400
                        }
                    },
                    'gatsby-remark-prismjs',
                    'gatsby-remark-copy-linked-files',
                    'gatsby-remark-smartypants',
                ],
            },
        },
        'gatsby-transformer-sharp',
        'gatsby-plugin-sharp',
        {
            resolve: 'gatsby-plugin-google-analytics',
            options: {
                trackingId: 'UA-162759252-1',
            },
        },
        'gatsby-plugin-remove-serviceworker',
        'gatsby-plugin-feed',
        'gatsby-plugin-react-helmet',
        {
            resolve: 'gatsby-plugin-typography',
            options: {
                pathToConfigModule: 'src/utils/typography',
            },
        },
        'gatsby-plugin-sass',
        {
            resolve: "gatsby-plugin-feed",
            options: {
                query: `
                  {
                    site {
                      siteMetadata {
                        title
                        description
                        siteUrl
                        site_url: siteUrl
                      }
                    }
                  }
                `,
              feeds: [
                {
                  query: `
                  {
                    allMarkdownRemark(sort: { fields: frontmatter___date, order: DESC }) {
                        nodes {
                          html
                          fields { slug }
                          frontmatter {
                            title
                            date
                            description
                            tag
                          }
                      }
                    }
                  }
                  `,
                  serialize: ({ query: { site, allMarkdownRemark } }) => {
                    return allMarkdownRemark.nodes.map(node => {
                      return Object.assign({}, node.frontmatter, {
                        description: node.frontmatter.description,
                        tag: node.frontmatter.tag,
                        date: node.frontmatter.date,
                        url: site.siteMetadata.siteUrl + node.fields.slug,
                        guid: site.siteMetadata.siteUrl + node.fields.slug
                      })
                    })
                  },
                  title: "Witczax.com blog RSS feed",
                  output: "/rss.xml",
                }
              ]
            }
          },
        {
            resolve: 'gatsby-plugin-mailchimp',
            options: {
                endpoint: 'https://witczax.us19.list-manage.com/subscribe/post?u=f4333059ddc7b1250e205e933&amp;id=ec9d5c5b56',
            },
          },
    ],
};
