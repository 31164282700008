import PropTypes from 'prop-types';
import React from 'react';
import { graphql } from 'gatsby';

import Comments from '../components/forms/Comments';
import Layout from '../components/layout';
import SEO from '../components/seo';
import { siteMetadata } from '../../gatsby-config';

class Post extends React.Component {
  // This will make links open in the new tab without me forcing update of entire Gatsby and everything... 🙈
  uglyHack() {
    setTimeout(function(){
      var links = document.links;
      for (var i = 0, linksLength = links.length; i < linksLength; i++) {
          if (links[i].hostname != window.location.hostname) {
              links[i].target = '_blank';
          }
      }
  }, 300);
 }

  render() {
    const post = this.props.data.markdownRemark;
    const siteTitle = this.props.data.site.siteMetadata.title;

    return (
      <Layout location={this.props.location} title={siteTitle} blogPostPage={true}>
        <SEO 
          site={this.props.data.site}
          title={post.frontmatter.title}
          description={post.frontmatter.description || post.excerpt} />
        <article className="post-container">
          <header className="post-header">
            <h1>{post.frontmatter.title}</h1>
            <p>{post.frontmatter.date}</p>
          </header>
          <section className="post-body" dangerouslySetInnerHTML={{ __html: post.html }} />

          <Comments />

          <footer>
          </footer>
        </article>
        {this.uglyHack()}
      </Layout>
    );
  }
}

Post.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      excerpt: PropTypes.string,
      frontmatter: PropTypes.shape({
        date: PropTypes.string,
        description: PropTypes.string,
        title: PropTypes.string
      }),
      html: PropTypes.string
    }),
    site: PropTypes.shape({
      siteMetadata: PropTypes.shape({
        title: PropTypes.string.isRequired
      }).isRequired
    }).isRequired,
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired
  }).isRequired,
  pageContext: PropTypes.shape({
    next: PropTypes.string.isRequired,
    previous: PropTypes.string.isRequired
  }).isRequired,
};

export default Post;

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        description
        siteUrl
        metaTitle
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
      }
    }
  }
`;
